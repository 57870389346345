/**
 * @generated SignedSource<<a397f00fc0f5084032913f00646578b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAppVisibility = "all" | "membership" | "admin" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventAccessFormFields_AppFragment$data = {
  readonly visibility: ProductAppVisibility;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupsMultiSelect_AppFragment">;
  readonly " $fragmentType": "EventAccessFormFields_AppFragment";
};
export type EventAccessFormFields_AppFragment$key = {
  readonly " $data"?: EventAccessFormFields_AppFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAccessFormFields_AppFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAccessFormFields_AppFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberGroupsMultiSelect_AppFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "ffca9385c787a05e3e82d29183fd3147";

export default node;
